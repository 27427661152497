import React from "react";
import Container from '@material-ui/core/Container';

export default function ayountungSyarat() {
    return (
        <Container maxWidth="xs" style={{ backgroundColor: '#eeeeee' }}>
            {/* <img src={SelularLogo}  alt="MReloadImage" style={{ height: '30px',margin: '15px 0 5px 0' }} /> */}
            <br/>
            <p><b>SYARAT DAN KETENTUAN AYOUNTUNG:</b></p>
            

            <div>
                <ul style={{listStyleType : 'decimal'}}>
                    <li>
                        AyoUntung adalah program yang dikembangkan dan dikelola oleh PT Ayopop Teknologi Indonesia (Ayoconnect) 
                        untuk Selular Group yang memberikan keuntungan lebih kepada pengguna dengan cara bertransaksi di 
                        Aplikasi M Reload.
                    </li>
                    <li>
                        AyoUntung hanya berlaku untuk pengguna yang mendapatkan info program ini melalui penawaran resmi di Aplikasi M Reload.
                    </li>
                    <li>
                        Pihak Selular Group memiliki hak penuh dalam pemilihan pengguna yang mendapatkan penawaran program AyoUntung, pengguna terpilih adalah mutlak, 
                        tidak dapat diubah dan tidak dapat diganggu gugat.
                    </li>
                    <li>
                        Untuk mengikuti program AyoUntung pengguna membayar biaya langganan sesuai dengan 
                        nilai yang tertera pada aplikasi. Pembayaran dapat dilakukan melalui semua metode 
                        pembayaran yang tersedia di Aplikasi M Reload.
                    </li>
                    <li>
                        Program AyoUntung berlaku selama 90 hari sejak pengguna berhasil melakukan pembayaran.
                    </li>
                    <li>
                        Selama periode program AyoUntung, pengguna berhak mendapatkan harga lebih murah untuk 
                        produk-produk tertentu sesuai dengan yang tercantum pada aplikasi M Reload. 
                        Harga produk yang ditawarkan untuk program AyoUntung dapat berubah sewaktu-waktu sesuai dengan 
                        kebijakan pihak Selular Group tanpa adanya pemberitahuan terlebih dahulu kepada pengguna.
                      
                    </li>
                    <li>
                        Selular Group berhak melakukan perubahan syarat dan ketentuan tanpa pemberitahuan terlebih dahulu.
                    </li>
                    <li>
                        Dengan mengikuti program AyoUntung, 
                        pengguna dianggap telah mengerti dan menyetujui semua syarat dan ketentuan yang berlaku di Aplikasi M Reload.
                    </li>
                    <li>
                        Harga Indikatif (*) 
                        yang tertera pada aplikasi dan website adalah perkiraan atas harga yang berlaku dan bukan merupakan harga final.
                    </li>

                </ul>
                <br/>
                <br/>
            </div>
            <br/>
        </Container>
    );
}
import React from "react";
import Container from '@material-ui/core/Container';
import SelularLogo from './reload.svg';

export default function Syarat() {
    return (
        <Container maxWidth="xs" style={{ backgroundColor: '#eeeeee' }}>
            <img src={SelularLogo}  alt="MReloadImage" style={{ height: '30px',margin: '15px 0 5px 0' }} />
            <p><b>Ketentuan Privasi</b></p>
            <div>
                <b style={{ display: 'block' }}>
                    Informasi Pengguna
                </b>
                <p>
                    Selular Group menghormati privasi pengguna dan berkomitmen untuk melindunginya dalam segala hal. Informasi tentang pengguna yang dikumpulkan oleh Selular Group adalah: (a) informasi yang diberikan oleh pengguna dan (b) informasi secara otomatis diberi oleh telepon genggam pengguna.
                    <br/>Dengan menggunakan layanan aplikasi M Reload, anda setuju untuk pengumpulan, penyimpanan, dan penggunaan informasi pribadi yang anda berikan untuk layanan yang kami tawarkan.
                    <br/>Untuk memanfaatkan aplikasi jasa M Reload, pengguna diharuskan untuk memberikan informasi tertentu untuk proses pendaftaran yang mungkin termasuk namun tidak terbatas pada : a) nama Anda, b) nomor telepon, c) password.
                </p>

            </div>

            <div>
                <b style={{ display: 'block' }}>
                    Berbagi Informasi
                </b>
                <p>
                    Selular Group berbagi informasi pribadi yang sensitif kepada pihak ketiga tanpa mendapatkan persetujuan terlebih dahulu dari Pengguna dalam keadaan terbatas berikut:
                </p>
                <ul>
                    <li style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        Ketika diminta atau diharuskan oleh hukum atau oleh pengadilan atau badan pemerintah 
                        atau otoritas untuk mengungkapkan, untuk tujuan verifikasi identitas, atau untuk pencegahan, deteksi, 
                        investigasi termasuk insiden cyber, atau untuk penuntutan dan hukuman dari pelanggaran. 
                        Pengungkapan ini dibuat dengan itikad baik dan keyakinan bahwa pengungkapan tersebut 
                        secara wajar diperlukan untuk menegakkan ketentuan ini; untuk mematuhi undang-undang dan peraturan 
                        yang berlaku.
                    </li>
                    <li>
                        Selular Group mengusulkan untuk berbagi informasi tersebut kepada partner atau 
                        perusahaan yang berkaitan dengan promosi untuk keuntungan anda. 
                        Promosi ini berupa dalam bentuk voucher, hadiah, atau cash-back
                    </li>
                    <li>
                        Selular Group berbagi informasi dengan PT Ayopop Teknologi Indonesia (Ayoconnect) 
                        selaku pengembang dan pengelola aplikasi Selular Group
                    </li>
                </ul>


            </div>


            <div>
                <b style={{ display: 'block' }}>
                    Informasi Keamanan
                </b>
                <p>
                    Kami mengambil langkah-langkah keamanan yang sesuai untuk melindungi terhadap akses tidak sah atau perubahan yang tidak sah, pengungkapan atau kerusakan data. Ini termasuk tinjauan internal pada pengumpulan data, penyimpanan dan pengolahan kami dan langkah-langkah keamanan, termasuk enkripsi yang sesuai dan langkah-langkah keamanan fisik untuk menjaga dari akses tidak sah ke sistem tempat kami menyimpan data pribadi. Semua informasi yang dikumpulkan dari M Reload disimpan dengan aman di dalam database yang dikelola oleh PT Ayopop Teknologi Indonesia (Ayoconnect). Database disimpan di server aman di belakang firewall; akses ke server adalah sandi - dilindungi dan sangat terbatas.
                    Namun internet adalah media yang terus berkembang. Kami dapat mengubah kebijakan privasi kami dari waktu ke waktu untuk menggabungkan perubahan masa depan yang diperlukan.
                    <br/>
                    Untuk keluhan, penyalahgunaan atau masalah berkaitan dengan konten dan atau komentar atau pelanggaran persyaratan ini akan segera diinformasikan ke Pengaduan Petugas yang ditunjuk seperti yang disebutkan di bawah ini melalui secara tertulis atau melalui email ditandatangani dengan tanda tangan elektronik.

                </p>
            </div>

            <p>
                Pangaduan Petugas 
            </p>
            <p>Email ke: <b><a href="mailto:info@ayopop.com" style={{color :'black'}}>info@ayopop.com</a></b></p>
            <p>Telepon: <b><a href="tel:+62 81703038000" style={{color :'black'}}>+62 81703038000</a> </b></p>
            
            <br/>
        </Container>
    );
}
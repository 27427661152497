import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ayoconnectLogo from './iclogoconnect.png';
import Step1 from './step1.png';
import Step2 from './step2.png';
import Step3 from './step3.png';
import Step4 from './step4.png';
import SelularLogo from './reload.svg';
import WAIcon from './Whatsapp.svg';
import TelIcon from './Phone.svg';
import Design1 from './g1.png';
import Design2 from './g2.png';
import categoryLogo from './app-preview.png';
import DownloadIcon from './icDownload.svg';
import {withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    logoImage : {
        height : '42px',
        display:'block',
        margin:'24px auto',
    },
    submit: {
        margin: theme.spacing(2, 0, 0),
        padding: '14px',
        backgroundColor: '#19A585',
        color:'white',
        fontWeight:'600',
        marginTop : '0',
        '&:hover': {
            backgroundColor: '#19A585',
        }
    },
    stepBlock : { 
        marginBottom :'24px',
        textAlign :'left'
    },
    stepNumber : {
        width : '24px',
        height : '24px',
        backgroundColor : '#19A585',
        color:'white',
        fontSize : '14px',
        lineHeight : '24px',
        borderRadius: '50%',
        fontWeight:'500',
        display:'inline-block',
        verticalAlign :'middle',
        textAlign :'center',
        marginRight:'16px'
    },
    stepDescription : {
        color:'#2a2a2a',
        fontSize : '14px',
        lineHeight : '24px',
        fontWeight:'400',
        display:'inline-block',
        width:'calc(100% - 111px)',
        verticalAlign :'middle'
    },
    stepImage : {
        width : '100%',
        marginTop : '16px'
    },
    footerblock :{ 
        backgroundColor : '#19A585',
        padding : '24px' ,
        position : 'relative'   
    },
    appIcon : {
        width :'18px',
        display:'inline-block',
        verticalAlign :'middle',
        marginRight : '20px'
    },
    Design1 : {
        position:'absolute',
        left:'0',
        bottom:'0'
    },
    Design2 : {
        position:'absolute',
        top:'0',
        right:'0'
    },
    appDetail : {
        color : 'white', 
        fontWeight : '400', 
        fontSize : '14px',
        lineHeight :'21px',
        display:'inline-block',
        verticalAlign :'middle',

    }
})

class MReload extends Component  {
//   constructor(props) {
//     super(props);
    
//   }

  componentDidMount(){
      if (!localStorage.getItem("login")){
        window.location.href = "/";
      }
      else {
         
      }
  }


  

  render(){
    const { classes } = this.props;
  return (
      <div>  
            <Container maxWidth="xs" style={{padding:'0px'}} >

                <img src={SelularLogo} alt="MReloadImage" className={classes.logoImage} />

                <div style={{backgroundColor : '#19A585',color:'white' ,padding : '24px 24px 100px 24px',textAlign :'center', position :'relative'}}>
                    <h4 style={{fontSize :'20px',margin :'0 0 16px 0' ,lineHeight : '24px', fontWeight :'600'}}> Selamat datang di laman aplikasi M_Reload. </h4>

                    <p style={{fontSize :'14px', lineHeight : '21px',margin :'0 0 20px 0' ,fontWeight :'400'}}>Nikmati kemudahan transaksi pembelian dan pembayaran berbagai produk digital untuk pelanggan Kamu.</p>

                    <img src={categoryLogo} alt="logoImage" style={{width:'100%'}}/>

                    <Paper style={{position :'absolute', bottom:'-10%', width:'calc(100% - 48px)', padding:'24px'}}>
                            <h4 style={{fontSize :'14px', lineHeight : '21px',color: '#2a2a2a', margin :'0 0 16px 0' ,fontWeight :'600'}}>
                                Silahkan download installer aplikasi
                                (file APK) kamu disini:     
                                
                            </h4>
                            <Button
                                type="button"
                                fullWidth
                                color="primary"
                                variant="contained"
                                href={process.env.REACT_APP_DOWNLOAD_APK_URL}
                                className={classes.submit}
                                onClick={()=>{localStorage.clear()}}
                                >
                                <img src={DownloadIcon} alt="logoImage" style={{marginRight : '12px',width:'12px'}} />
                                DOWNLOAD APK
                            </Button>
                    </Paper>
                </div>


                <div style={{marginTop :'100px', padding:'24px'}}>
                    <h4 style={{fontSize :'20px',margin :'0 0 16px 0',color:'#333333',textAlign:'center',lineHeight : '24px', fontWeight :'600'}}>
                        Panduan pengaturan download dan install APK
                    </h4>

                    <div className={classes.stepBlock}>
                        <div>
                            <div className={classes.stepNumber}>
                                    1
                            </div>
                            <div className={classes.stepDescription}>
                                Tap OK untuk melanjutkan download 
                                file APK
                            </div>
                        </div>
                        <img src={Step1}  alt="stepImage" className={classes.stepImage}/>
                    </div>


                    <div className={classes.stepBlock}>
                        <div>
                            <div className={classes.stepNumber}>
                                    2
                            </div>
                            <div className={classes.stepDescription}>
                                Buka file APK untuk melanjutkan instalasi di smartphone Kamu
                            </div>
                        </div>
                        <img src={Step2} alt="stepImage" className={classes.stepImage}/>
                    </div>

                    <div className={classes.stepBlock}>
                        <div>
                            <div className={classes.stepNumber}>
                                    3
                            </div>
                            <div className={classes.stepDescription}>
                            Pastikan pengaturan di smartphone Kamu mengizinkan instalasi apk dari sumber selain Play Store.
                            </div>
                        </div>
                        <img src={Step3} alt="stepImage" className={classes.stepImage}/>
                    </div>


                    <div className={classes.stepBlock}>
                        <h4 style={{fontSize :'14px', lineHeight : '21px',color: '#2a2a2a', margin :'0' ,fontWeight :'600'}}>Pengaturan dari Google Chrome</h4>
                        <img src={Step4} alt="stepImage" className={classes.stepImage}/>
                    </div>


                    

                </div>

                <div className={classes.footerblock}>
                     
                     <img src={Design2} alt="PlaceholderImage" className={classes.Design2} />
                     <div style={{marginBottom : '46px'}}>
                        <p style={{ color : 'white', fontWeight : '500', fontSize : '12px',lineHeight :'14px', margin : '6px 0'}}>
                            Powered By
                        </p>
                        <img src={ayoconnectLogo} alt="Ayoconnect Logo" style={{height :'22px'}}/>
                     </div>


                     <div>
                        <p style={{ color : 'white', fontWeight : '400', fontSize : '14px',lineHeight :'21px', margin : '6px 0'}}>Customer Service </p>
                        <div style={{marginBottom : '10px'}}>
                            <a href="tel:+021-4001-1091">
                                <img src={TelIcon} alt="PhoneImage" className={classes.appIcon}/>
                                <span className={classes.appDetail} > 021-4001-1091</span>
                               
                            </a> 
                        </div>
                               
                        <div style={{marginBottom : '10px'}}>

                            <a href="whatsapp://send?abid=0856-1234-1234&text=Connect%2C%20Ayoconnect!">
                            <img src={WAIcon} alt="WhatsAppImage" className={classes.appIcon}/>
                            <span className={classes.appDetail}>0856-1234-1234</span>  
                            </a>
                        </div>
                       
                        
                        <a href="/syarat-ketentuan" style={{ margin : '20px 0',display :'block',color : 'white',fontSize : '14px',lineHeight :'14px', fontWeight:'600',zIndex:'2',position:'relative',textDecoration :'none'}}>
                            Syarat dan Ketentuan
                        </a>
                        <a href="/kebijakan-privasi" style={{ margin : '20px 0',display :'block',color : 'white',fontSize : '14px',lineHeight :'14px', fontWeight:'600',zIndex:'2',position:'relative',textDecoration :'none'}}>
                            Kebijakan Privasi
                        </a>
                        <img src={Design1} alt="placeholderImage" className={classes.Design1} />
                     </div>
                </div>


                
                
            </Container>  
      </div>
            
   )};
}


export default withStyles(styles)(MReload);



import React from "react";
import Container from '@material-ui/core/Container';
import SelularLogo from './reload.svg';

export default function Syarat() {
    return (
        <Container maxWidth="xs" style={{ backgroundColor: '#eeeeee' }}>
            <img src={SelularLogo}  alt="MReloadImage" style={{ height: '30px', margin: '15px 0 5px 0' }} />
            <p>
                Terima kasih telah menggunakan aplikasi M Reload dan mempercayakan deposit dan informasi data diri anda kepada M Reload.
                Penting bagi Kami untuk menjaga kepercayaan Anda. Untuk itu, Kami perlu Anda membaca dan
                menyetujui langkah-langkah yang Kami terapkan untuk menjaga kepercayaan Anda,
                yang Kami jabarkan dalam syarat dan ketentuan dibawah ini.
            </p>

            <b>DEFINISI</b>
            <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                <li>
                    <b>"Aplikasi M Reload"</b> merupakan aplikasi yang dikembangkan oleh PT Ayopop Teknologi Indonesia (Ayoconnect) untuk Selular Group yang mana adalah aplikasi yang Anda unduh (download) sehingga Anda masuk pada Laman ini atau Syarat dan Ketentuan, yang saat ini dikenal dengan merek, nama, logo dan/atau tanda yang dikenal dengan “M Reload” atau merek, nama, logo dan/atau tanda lainnya dan hanya dapat di download pada perangkat Android saja

            </li>
                <li>
                    <b>“Deposit” </b>adalah sejumlah dana yang disetorkan kepada Selular Group sehingga anda dapat memperoleh Produk/Layanan melalui aplikasi yang disediakan oleh Selular Group.

            </li>
                <li>
                    <b>“Akun”</b> atau <b>“Akun Anda”</b>
                adalah identifikasi khusus yang dibuat di Aplikasi M Reload berdasarkan permintaan pendaftaran Anda.
            </li>
                <li>
                    <b>“Data”</b> adalah setiap data, informasi dan/atau keterangan dalam bentuk apapun yang dari waktu ke waktu (termasuk pada saat Anda mengunduh (download) Aplikasi M Reload)
                Anda sampaikan kepada Kami/Penyedia Layanan atau yang Anda cantumkan atau sampaikan dalam, pada atau melalui Aplikasi ini.
            </li>
                <li>
                    <b>“Kami”</b>  adalah Selular Group.
            </li>
                <li>
                    <b>“Produk atau Layanan”</b> berarti setiap layanan, program, jasa, produk, fitur,
                sistem, fasilitas dan/atau jasa yang dikembangkan oleh Ayoconnect dan tersedia dalam Aplikasi M Reload.
            </li>
                <li>
                    <b>“Layanan Pelanggan (Call Center Ayoconnect)” </b>adalah fungsi customer service center yang didukung oleh PT Ayopop Teknologi Indonesia (Ayoconnect)
                untuk pengguna yang dapat dihubungi lewat panggilan telepon, chat dan/atau email.
            </li>
                <li>
                    <b>“Pengguna M Reload”</b> adalah pengguna Aplikasi M Reload.
            </li>
                <li>
                    <b>“Syarat dan Ketentuan” </b>berarti Syarat dan Ketentuan ini berikut setiap perubahan, penambahan, penggantian, penyesuaian dan/atau modifikasinya yang dibuat dari waktu ke waktu.
            </li>
                <li>
                    <b>“Transaksi”</b> berarti segala transaksi, kegiatan, aktivitas dan/atau aksi yang dilakukan dalam atau melalui Aplikasi / Akun, termasuk penggunaan Layanan atau fitur - fitur tertentu dalam Layanan atau Aplikasi.
            </li>

            </ul>

            <b>Jenis Akun Aplikasi M Reload</b>
            <table style={{ borderColor: '#000000', backgroundColor: ' #eeeeee', float: 'left', borderSpacing: '0px', margin: '20px 0' }} border="1px">
                <thead>
                    <tr>
                        <th>Jenis Akun</th>
                        <th>Standard</th>
                        <th>Verified</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Deposit Maksimum
                    </td>
                        <td>
                            RP 2 Juta
                    </td>
                        <td>
                            Rp 10 Juta
                            (M Reload membatasi nilai Deposit maksimal sebesar Rp. 5 Juta per transaksi Deposit)
                    </td>
                    </tr>
                    <tr>
                        <td>
                            Batas Transaksi
                    </td>
                        <td colSpan={2}>
                            Setiap pengguna diperbolehkan untuk transaksi sampai batas maksimal transaksi berupa Rp 20 juta rupiah setiap bulannya.
                    </td>
                    </tr>
                    <tr>
                        <td>
                            Fitur Transaksi
                    </td>
                        <td>
                            <ul>
                                <li>
                                    Pengisian Deposit;
                            </li>
                                <li>
                                    Pembayaran tagihan;
                            </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Pengisian Deposit;
                            </li>
                                <li>
                                    Pembayaran tagihan;
                            </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Fitur Keamanan
                    </td>
                        <td>
                            <ul>
                                <li>
                                    Mobile Application Password (Fingerprint Support)
                            </li>
                                <li>
                                    Fitur PassCode
                            </li>
                                <li>
                                    One Time Password (OTP)
                            </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>
                                    Mobile Application Password (Fingerprint Support)
                            </li>
                                <li>
                                    Fitur PassCode
                            </li>
                                <li>
                                    One Time Password (OTP)
                            </li>
                            </ul>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            Persyaratan
                    </td>
                        <td>
                            Pendaftaran pada Aplikasi M Reload dengan menyertakan:
                    <ul>
                                <li>Nama lengkap;</li>
                                <li>Nomor telepon/ HP;</li>
                                <li>Rentang Umur.</li>
                            </ul>
                    Menyetujui Syarat dan ketentuan penggunaan Aplikasi M Reload.
                    </td>
                        <td>
                            Pendaftaran pada Aplikasi M Reload dengan menyertakan:
                    <ul>
                                <li>Foto Kartu Identitas - KTP;</li>
                                <li>Foto Kartu Identitas - SIM, Paspor, KITAS (akan datang);</li>
                                <li>Foto Selfie dengan kartu identitas asli;</li>
                                <li>Nomor kartu identitas;</li>
                                <li>Foto tanda tangan atau fingerprint. (Akan datang)</li>
                        Menyetujui Syarat dan ketentuan penggunaan Aplikasi M Reload.
                    </ul>
                        </td>
                    </tr>

                </tbody>
            </table>

            {/* tnc */}
            <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                <li>
                    <b style={{ display: 'block' }}> Syarat dan ketentuan</b>
                    Syarat dan ketentuan ini berlaku terhadap seluruh pengguna user salah satu atau beberapa atau seluruh layanan yang tersedia di M Reload,
                    dan terhadap setiap orang yang menyampaikan permintaan atau informasi kepada Kami.
                </li>

                <li>
                    <b style={{ display: 'block' }}>Registrasi Akun</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>
                            Anda perlu untuk melakukan registrasi di Aplikasi M Reload dengan menyampaikan informasi yang benar, tepat dan terbaru dari data diri Anda.
                        </li>
                        <li>
                            Anda perlu memahami bahwa detail informasi log-in Anda adalah rahasia dan karenanya Anda tidak akan mengungkapkan detail
                            informasi log-in Anda kepada pihak ketiga manapun, termasuk password rahasia berupa <b>Kode Sandi Sekali Pakai (One Time Password/OTP)</b> yang dikirimkan melalui
                            SMS ke nomor telepon terdaftar Anda setiap kali ada percobaan log-in ke akun Anda. Kami atau petugas Kami tidak akan menanyakan kode sandi Anda (kecuali pada halaman di aplikasi M Reload Anda
                            ketika Anda masuk ke Akun M Reload Anda melalui aplikasi M Reload di ponsel pintar Anda). Anda setuju untuk menanggung setiap risiko terkait pengungkapan informasi log-in Anda kepada pihak
                            ketiga manapun dan bertanggung jawab penuh atas setiap konsekuensi yang berkaitan dengan hal tersebut.
                        </li>

                        <li>
                            Anda akan diminta untuk menyampaikan dokumen atau informasi tambahan,
                            baik secara elektronik atau langsung menemui agen Kami, sebagaimana Kami instruksikan,
                            jika Anda ingin menggunakan layanan tertentu yang disediakan bagi pengguna M Reload
                            terverifikasi atau sebagaimana diperlukan.
                        </li>

                        <li>
                            Kami akan melakukan verifikasi atas informasi dan dokumen yang Anda sampaikan dan
                            menentukan apakah Anda telah memenuhi persyaratan sebagai calon pengguna M Reload terverifikasi.

                        </li>

                        <li>
                            Persetujuan dan/atau penolakan atas permohonan verifikasi Anda merupakan kewenangan
                            kami sepenuhnya dengan memperhatikan ketentuan hukum yang berlaku. Kami sewaktu-waktu dapat menarik persetujuan atas permohonan verifikasi
                            Anda dan mengembalikan status penggunaan Anda menjadi pengguna M Reload tidak terverifikasi (jika sebelumnya Anda telah menjadi pengguna M Reload terverifikasi) apabila terdapat keraguan atas
                            kebenaran dan/atau ke-sah-an serta ketidaklengkapan dokumen dan/atau informasi yang Anda sampaikan.
                        </li>
                        <li>
                            Untuk menghindari penyalahgunaan Akun M Reload Anda, Kami akan menghentikan layanan M Reload
                            jika dokumen atau informasi yang Kami minta tidak dilengkapi, tidak disampaikan dan/atau
                            dilengkapi atau disampaikan namun tidak sesuai.

                        </li>
                        <li>
                            Registrasi akun M Reload hanya dapat dilakukan oleh Anda sendiri sebagai subjek hukum.
                            Anda menjamin bahwa registrasi akun M Reload Anda ditujukan untuk kepentingan Anda sendiri,
                            bukan kepentingan pihak manapun, termasuk badan hukum dan/atau badan usaha manapun.
                            Kami berhak menolak registrasi akun M Reload yang Anda lakukan untuk kepentingan pihak selain Anda,
                            termasuk badan hukum dan/atau badan usaha manapun.

                        </li>
                    </ul>

                </li>

                <li>
                    <b style={{ display: 'block' }}>Informasi Pribadi</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>
                            Sebagai bagian dari upaya untuk menjaga informasi di akun M Reload Anda selalu aktual,
                            Anda perlu untuk segera menyampaikan kepada Kami perubahan atas informasi data pribadi
                            yang telah Anda sampaikan sebelumnya beserta bukti pendukungnya,
                            termasuk namun tidak terbatas pada alamat surat elektronik, nomor telepon seluler yang
                            Anda daftarkan pada saat pembukaan akun M Reload Anda dan/atau dokumen lainnya yang Anda
                            sampaikan dalam proses pengajuan sebagai pengguna M Reload terverifikasi.

                        </li>
                        <li>
                            Penyampaian dokumen atau informasi kepada Kami, termasuk namun tidak terbatas pada dokumen atau
                            informasi saat registrasi atau informasi saat memberikan komentar pada layanan Kami di halaman ulasan,
                            merupakan persetujuan Anda atas pengumpulan, penggunaan dan/atau pengungkapan oleh
                            Kami atas informasi pribadi yang Anda sampaikan, sesuai dengan hukum dan peraturan perundang-undangan yang berlaku.
                        </li>
                        <li>
                            Kami tidak akan mengungkapkan informasi pribadi Anda kepada pihak manapun, kecuali kepada
                            PT Ayopop Teknologi Indonesia (Ayoconnect) selaku pengembang dan pengelola aplikasi M Reload,
                            instansi pemerintah, moneter, pengawas jasa sistem pembayaran, otoritas berwenang lainnya termasuk pihak
                            kepolisian dalam suatu kasus pidana dan pihak ketiga lainnya yang secara langsung berkepentingan dalam
                            pemrosesan layanan (termasuk pelaku usaha),
                            sepanjang dimungkinkan berdasarkan hukum dan peraturan perundang-undangan yang berlaku.
                        </li>
                    </ul>
                </li>

                <li>
                    <b style={{ display: 'block' }}>Persetujuan</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>
                            Anda menyatakan dan menjamin bahwa Anda adalah individu yang sah secara hukum untuk terikat
                            dalam perjanjian berdasarkan hukum Republik Indonesia, secara khusus terikat dalam Syarat dan Ketentuan
                            ini untuk menggunakan M Reload dan bahwa Anda berusia minimal 21 tahun atau telah menikah dan tidak sedang
                            berada dalam pengampuan.

                        </li>
                        <li>
                            Jika Anda tidak memenuhi syarat tersebut, Anda menyatakan dan menjamin bahwa pembukaan akun
                            Anda dan aktivitas lain yang Anda lakukan menggunakan M Reload telah disetujui oleh orang tua atau pengampu Anda.
                        </li>
                    </ul>

                </li>

                <li>
                    <b style={{ display: 'block' }}>Pengisian Deposit</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>
                            Pengisian Deposit hanya dapat dilakukan melalui transfer ke rekening 
                            bank yang disediakan oleh Selular Group; 
                            Bank BCA a/n PT Selular Media Infotama: 1983050585 
                            Anda tidak dapat mengisi deposit di aplikasi M Reload melalui pihak ketiga. 

                        </li>
                        <li>
                            Saldo Deposit Anda hanya dapat digunakan untuk layanan pembayaran tagihan dan
                            pembelian produk-produk digital yang tersedia pada aplikasi Kami. Deposit Anda
                            tidak dapat digunakan untuk kegiatan diluar layanan yang tersedia pada Aplikasi Kami.

                        </li>
                    </ul>

                </li>
                {/* end pt 5 */}
                <li>
                    <b style={{ display: 'block' }}> Batasan Deposit dan Transaksi </b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>Jumlah Deposit Anda dibatasi sebesar: (a) Rp. 2.000.000,- (dua juta Rupiah) jika akun Anda adalah jenis Standard; atau (b) Rp. 10.000.000,- (sepuluh juta Rupiah) jika akun Anda adalah jenis Verified; atau (c) jumlah lain yang lebih kecil sebagaimana Kami tentukan.</li>
                        <li>Bahwa pengisian Deposit jenis Verified hanya dapat dilakukan dengan nominal maksimal Rp. 5.000.000,- (lima juta Rupiah).</li>
                        <li>Nilai transaksi pengisian deposit anda tidak dapat melebihi Rp 20.000.000,- (dua puluh juta Rupiah) per bulan kalender.</li>
                        <li>Jika Deposit atau transaksi bulanan akun Anda melewati batas tersebut, maka Kami berhak melakukan verifikasi sebelum melakukan tindakan lebih lanjut, termasuk namun tidak terbatas pada tidak menjalankan perintah transaksi yang Anda sampaikan.</li>
                        <li>Kami perlu Anda memahami bahwa Kami bukan bank. Deposit Anda bukan merupakan tabungan berdasarkan pengertian hukum yang berkaitan dengan perbankan, tidak tunduk pada program perlindungan oleh Lembaga Penjamin Simpanan dan tidak berhak atas setiap fitur yang umumnya melekat pada rekening bank (seperti bunga, dsb).</li>
                    </ul>
                </li>


                <li>
                    <b style={{ display: 'block' }}> Penggunaan </b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>Aplikasi M Reload dapat digunakan untuk layanan yang disediakan oleh Selular Group.</li>
                        <li>Deposit Anda dapat digunakan atau ditransaksikan seluruhnya sampai Deposit nihil sesuai dengan syarat dan ketentuan ini.</li>
                        <li>Cara penggunaan dan/atau metode pengisian deposit dapat berubah dan/atau bertambah dari waktu ke waktu, sebagaimana Kami informasikan. Informasi tersebut akan kami sampaikan terlebih dahulu kepada Pengguna melalui cara – cara yang akan kami tetapkan.</li>
                        <li>Deposit Anda akan dikurangi secara otomatis dengan jumlah yang dimasukkan atau dikonfirmasi oleh Anda untuk pembayaran terkait, selama Deposit Anda mencukupi. Jika Deposit Anda tidak mencukupi pembayaran terkait, Kami dapat menolak memproses pembayaran Anda atau jika layanan Kami memungkinkan pembayaran sebagian (partial payment), maka Anda akan diwajibkan membayar selisihnya menggunakan uang tunai atau cara lainnya yang kami tentukan.</li>
                        <li>Transaksi yang telah Anda konfirmasi dan Kami proses, tidak dapat dibatalkan. Bermakna anda telah setuju dengan syarat dan ketentuan yang telah kami tentukan dan pembatalan hanya dapat dilakukan apabila terbukti kesalahan ada di Pihak Selular Group.</li>
                        <li>Anda memahami bahwa Kami merupakan penyedia sistem pembayaran tagihan atau produk digital. Setiap keluhan dan/atau klaim terkait barang dan/atau jasa yang disediakan oleh pihak ketiga wajib Anda sampaikan langsung kepada pihak ketiga terkait.</li>
                        <li>Perlu Anda mengerti bahwa penggunaan akun M Reload Anda dan/atau layanan M Reload merupakan tanggung jawab pribadi Anda.
                                <b>JANGAN MEMBERI AKSES ATAU MEMBIARKAN ORANG LAIN UNTUK MENGAKSES AKUN M Reload Anda</b>.
                                 Anda setuju bahwa setiap perintah transaksi yang dibuat melalui akun M Reload Anda akan dianggap telah diotorisasi oleh Anda atau berdasarkan perintah / persetujuan Anda dan oleh karenanya adalah perintah transaksi Anda. Kami tidak bisa dan tidak akan bisa dimintakan pertanggungjawaban atas setiap kerugian atau klaim yang timbul karena penggunaan akun M Reload anda dan/atau layanan M Reload yang dilakukan dengan melanggar Syarat dan Ketentuan ini.</li>
                    </ul>
                </li>

                <li>

                    <b style={{ display: 'block' }}> PPIN (Pengamanan Akun)</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>Sebagai bagian dari upaya Kami untuk menjaga keamanan akun M Reload Anda, Anda perlu untuk mendaftarkan enam urut angka sebagai kode PIN akun M Reload Anda.</li>
                        <li>Kode PIN Anda akan diperlukan untuk pembayaran dan/atau pemanfaatan fitur-fitur tertentu yang Kami tetapkan.</li>
                        <li>Anda wajib untuk menjaga kerahasiaan kode PIN Anda. Kerugian yang timbul akibat kegagalan Anda untuk menjaga kerahasiaan kode PIN Anda merupakan tanggung jawab Anda sepenuhnya.</li>
                    </ul>
                </li>

                <li>
                    <b style={{ display: 'block' }}> Pemblokiran Akun</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                        <li>Atas permintaan Anda, Kami akan memblokir akun M Reload Anda dan penggunaan layanan M Reload dengan akun M Reload Anda. Selain itu, jika menurut pertimbangan Kami, terjadi hal-hal yang merugikan dan/atau dapat merugikan Kami atau Anda, Kami juga akan melakukan pemblokiran tersebut. Namun demikian, kami menyediakan jalur pengajuan keluhan bagi Anda sesuai ketentuan butir 9.3 di bawah.</li>
                        <li>Pemblokiran akun dapat terjadi termasuk, namun tidak terbatas pada, kejadian-kejadian berikut ini:
                                <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                                <li>Laporan Anda bahwa telepon selular Anda hilang atau dicuri atau diretas;</li>
                                <li>
                                    Perintah pemblokiran yang diterbitkan oleh institusi pemerintah atau moneter atau aparat penegak hukum berdasarkan perintah pengadilan;
                                    </li>
                                <li>
                                    Akun M Reload tidak digunakan untuk jangka waktu minimum selama 6 bulan berturut-turut;
                                    </li>
                                <li>
                                    Penggunaan akun M Reload dan/atau layanan M Reload Anda dengan cara yang bertentangan dengan Syarat dan Ketentuan ini, ketentuan penggunaan aplikasi M Reload, syarat dan ketentuan lain yang berlaku untuk fitur atau layanan lain di aplikasi M Reload, kebijakan privasi M Reload dan setiap hukum dan peraturan perundang-undangan yang berlaku
                                    </li>
                                <li>
                                    Alasan lain yang merugikan Anda atau Kami, termasuk tindakan fraud atau ketika sistem kami menangkap pola transaksi yang mencurigakan.
                                    </li>
                            </ul>
                        </li>
                        <li>Jika akun M Reload Anda diblokir dan Anda memiliki bukti yang kuat bahwa tidak ada hal mencurigakan yang terjadi, Anda dapat mengajukan keluhan Anda sesuai dengan yang tertera pada Syarat dan Ketentuan ini. Setelah memeriksa keluhan Anda, kami dapat menentukan untuk mengakhiri atau melanjutkan pemblokiran akun M Reload Anda. Pemblokiran tidak akan diteruskan secara tidak wajar apabila Kami memutuskan bahwa hal-hal yang mengakibatkan terjadinya pemblokiran telah berakhir.</li>
                    </ul>
                </li>

                <li>
                    <b style={{ display: 'block' }}> Penutupan Akun</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>

                        <li>Akun M Reload Anda dapat ditutup karena hal-hal sebagai berikut:
                                <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                                <li>Permintaan oleh Anda;</li>
                                <li>
                                    Kebijakan Kami berdasarkan hukum dan peraturan perundang-undangan yang berlaku;
                                    </li>
                                <li>
                                    Force Majeure terjadi selama 3 (tiga) bulan atau lebih secara berturut-turut; dan/atau
                                    </li>
                                <li>
                                    Alasan lain sehubungan dengan pemblokiran akun.
                                    </li>

                            </ul>
                        </li>

                        <li>Kami akan mengembalikan Deposit Anda melalui metode yang disepakati bersama antara para pihak, setelah dikurangi dengan biaya yang terutang (jika ada), kecuali jika berdasarkan pertimbangan Kami diperlukan adanya penahanan bagian tertentu dari Deposit Anda, misalnya karena keyakinan yang wajar bahwa bagian tersebut berhubungan dengan atau dihasilkan dari penipuan dan/atau tindak pelanggaran hukum.</li>
                        <li>Akun M Reload Anda akan diakhiri secara otomatis atau ditutup ketika Anda menutup akun M Reload Anda.</li>
                    </ul>
                </li>

                <li>
                    <b style={{ display: 'block' }}>Penolakan Transaksi</b>
                    Kami dapat menolak transaksi yang dilakukan oleh Anda jika Anda melanggar sebagian atau seluruh Syarat dan Ketentuan ini dan/atau melanggar hukum dan peraturan yang berlaku.
                </li>

                <li>
                    <b style={{ display: 'block' }}>Biaya</b>
                    Dalam penggunaan akun M Reload dan/atau layanan M Reload, Anda dapat dikenakan biaya, yang dapat ditetapkan oleh kami dan/atau pihak ketiga, atau biaya atas pengaktifan kembali akun M Reload yang telah dinonaktifkan.

                </li>


                <li>
                    <b style={{ display: 'block' }}> Tanggung Jawab dan Kewajiban Anda</b>
                    <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>

                        <li>Sebagai tambahan dari tanggung jawab Anda yang diatur dalam bagian lain dari Syarat dan Ketentuan ini, Anda bertanggung jawab atas
                                <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                                <li>Keamanan telepon seluler Anda yang digunakan untuk mengakses akun M Reload termasuk secara wajar menjaga dan menyediakan memori penyimpanan yang cukup untuk mencegah setiap kegagalan atau gangguan atas setiap proses layanan M Reload yang
                                disebabkan oleh kegagalan fungsi telepon seluler Anda;
                                    </li>
                                <li>
                                    Kemanan dan validitas nomor telepon seluler yang Anda daftarkan untuk mengakses akun
                                    M Reload, karenanya Kami tidak bertanggung jawab atas setiap klaim yang timbul jika
                                    nomor telepon seluler yang Anda daftarkan dikuasai oleh pihak lain, menjadi tidak
                                    aktif berdasarkan kebijakan perusahaan telekomunikasi dan/atau menjadi dormant;

                                    </li>
                                <li>Setiap transaksi yang Anda lakukan, Anda wajib berhati-hati ketika menggunakan akun M Reload dan/atau
                                layanan Selular Group, termasuk namun tidak terbatas pada ketika memilih menu transaksi, tujuan pembayaran, memasukkan nilai pembayaran dan lain sebagainya. Anda akan memastikan bahwa Anda hanya akan mengikuti instruksi transaksi yang diterbitkan oleh
                                Selular Group sesuai transaksi yang Anda setujui; dan
                                    </li>
                                <li>
                                    Setiap kerugian, klaim, tuntutan dan/atau biaya yang timbul, termasuk dari pihak lain kepada Kami, yang timbul
                                    karena kesalahan Anda dalam menggunakan akun M Reload, layanan Selular Group, fitur M Reload dan/atau aplikasi M Reload,
                                    termasuk namun tidak terbatas pada yang dikarenakan oleh ketidak hati-hatian dalam bertindak,
                                    kecerobohan atau penyalahgunaan akun
                                    M Reload dan/atau layanan Selular Group oleh Anda atau pihak lain yang menggunakan akun M Reload Anda.
                                    </li>


                            </ul>
                        </li>

                        <li>
                            <b style={{ display: 'block' }}>Tanggung Jawab Kami</b>
                                Kami tidak bertanggung jawab atas kerugian tidak langsung, termasuk biaya, bunga,
                                kerusakan atau kerugian dalam bentuk atau jenis apapun (termasuk cidera pribadi,
                                tekanan emosional dan hilangnya data, barang, pendapatan, laba, penggunaan atau keuntungan
                                ekonomis lainnya) yang Anda atau pihak ketiga alami. Kami hanya bertanggung jawab atas
                                kerugian langsung, yakni jumlah yang senyatanya dibayar, yang timbul atau sehubungan dengan
                                penggunaan akun M Reload Anda, yang dapat dibuktikan terjadi karena kelalaian berat atau
                                pelanggaran Kami atas Syarat dan Ketentuan ini. Dalam setiap hal apapun, maksimal tanggung
                                jawab Kami hanya terbatas pada jumlah terakhir Deposit Anda yang ada di sistem Kami atau
                                Rp. 5.000.000 (lima juta Rupiah), mana yang lebih rendah.

                            </li>
                        <li>
                            <b style={{ display: 'block' }}>Ganti Rugi</b>
                                Dengan mendaftar dan menggunakan akun M Reload dan layanan Selular Group, Anda setuju untuk membebaskan Kami,
                                afiliasi Kami dan setiap pegawai, direktur, komisaris, karyawan, wakil dan agen Kami, dari dan terhadap
                                setiap dan seluruh klaim, biaya, kerusakan,
                                kerugian, tanggung jawab dan pengeluaran (termasuk biaya advokat) yang timbul atau sehubungan dengan:
                                <ul style={{ listStyleType: 'decimal' }}>
                                <li>
                                    Pelanggaran Anda atas Syarat dan Ketentuan ini atau ketentuan hukum dan peraturan perundang-undangan yang berlaku,
                                    baik direferensikan atau tidak di sini;
                                    </li>
                                <li>
                                    Pelanggaran Anda atas hak pihak ketiga lainnya sehubungan dengan transaksi di
                                    M Reload dan/atau layanan Selular Group;
                                    </li>
                                <li>
                                    Setiap penggunaan atau penyalahgunaan M Reload dan/atau layanan
                                    Selular Group oleh Anda atau pihak lain menggunakan akun M Reload Anda;
                                    </li>
                                <li>
                                    Setiap kesalahan yang dilakukan oleh pihak selain Kami,
                                    baik disengaja maupun berupa kelalaian; atau
                                    </li>
                                <li>
                                    Pemblokiran akun M Reload Anda berdasarkan hal-hal yang diatur dalam
                                    Syarat dan Ketentuan ini.
                                    </li>
                            </ul>
                                Ketentuan ganti rugi ini akan tetap berlaku terlepas dari penutupan akun M Reload Anda.

                            </li>

                        <li>
                            <b style={{ display: 'block' }}>Permasalahan dan Keluhan</b>
                            <ul style={{ listStyleType: 'decimal' }}>
                                <li> Kami melakukan usaha terbaik untuk menjaga keamanan sistem, termasuk sistem pencatatan transaksi
                                Anda secara elektronik di server Kami. Data dan Depositdan/atau sejarah transaksi yang dicatat di sistem
                                Kami merupakan data dan Deposit dan/atau sejarah transaksi yang final dan mengikat Kami dan Anda.
                                    </li>

                                <li>
                                    Kami memberlakukan standar keamanan yang wajar untuk melindungi data Anda yang sedang dalam proses pengiriman, ketika disimpan dan
                                    penggunaan Anda atas akun M Reload dan/atau layanan Selular Group. Meskipun demikian, Kami hendak menekankan kepada Anda bahwa tidak ada sistem yang tidak dapat ditembus dan hal ini dapat berakibat pada meningkatnya resiko atas informasi Anda dan penggunaan Akun M Reload dan/atau Layanan Selular Group. Oleh karenanya, sejauh dimungkinkan berdasarkan hukum dan peraturan perundang-undangan yang berlaku, Anda setuju untuk melepaskan Kami dari klaim apapun yang timbul sehubungan dengan adanya virus, kerusakan, gangguan, bentuk lain dari gangguan sistem yang diakibatkan oleh pihak selain kami atau akses tanpa otorisasi yang dilakukan oleh pihak ketiga dengan itikad tidak baik. Kami menganjurkan Anda untuk memberitahu Kami segera jika
                                    Anda mengalami gangguan sistem apapun sebagaimana disebutkan di atas sehingga Kami dapat berusaha memperbaiki gangguan tersebut.
                                    </li>

                                <li>
                                    Anda setuju untuk tidak merusak, mengubah atau memodifikasi akun M Reload Anda atau mencoba merusak,
                                    mengubah atau memodifikasinya dengan cara apapun.

                                    </li>
                                <li>
                                    Kami tidak bertanggung jawab jika Anda tidak memiliki perangkat yang memadai atau jika
                                    Anda mengunduh versi yang salah dari Aplikasi M Reload ke perangkat Anda.

                                    </li>
                                <li>
                                    Kami berhak untuk melarang Anda menggunakan M Reload dan/atau layanan Selular Group
                                    lebih lanjut jika Anda menggunakan dengan perangkat yang tidak memadai atau perangkat yang tidak
                                    terotorisasi atau untuk tujuan lain sebagaimana untuk aplikasi M Reload dan/atau layanan
                                    Selular Group dimaksudkan untuk digunakan.
                                    </li>
                                <li>
                                    Kami perlu Anda memahami bahwa dalam hal pengisian deposit melalui pihak ketiga, termasuk namun tidak terbatas pada bank tertentu,
                                    terdapat kemungkinan kegagalan sistem dari pihak ketiga tersebut. Kami tidak bertanggung jawab atas masalah ini.
                                    </li>
                                <li>
                                    Jika telepon seluler Anda hilang, dicuri dan/atau diretas,
                                    mohon segera memberitahu Kami melalui akun surat elektronik yang terdaftar di akun
                                    M Reload Anda atau melalui telepon ke Customer Care Ayoconnect. Jika terjadi transaksi setelah telepon seluler Anda hilang, dicuri dan/atau diretas, namun Anda belum memberitahukan kepada Kami dengan cara demikian, maka pembayaran yang terjadi dianggap sah.

                                    </li>
                                <li>
                                    Jika Anda memiliki pertanyaan, permintaan,
                                    keluhan atau kepentingan penyampaian informasi apapun terkait fitur M Reload,
                                    Anda dapat menyampaikannya melalui surat elektronik ke alamat info@Ayopop.com atau telepon ke (021) 40011091.
                                        <br />
                                        Untuk merespon pertanyaan atau keluhan Anda, Kami akan terlebih dahulu melakukan verifikasi atas data Anda. Kami berhak untuk menolak pemrosesan pertanyaan atau keluhan, jika data Anda tidak sesuai dengan data yang tertera pada sistem Kami.
                                        <br />
                                        Kami akan memeriksa atau melakukan verifikasi keluhan Anda dan menyampaikan respon Kami sesuai dengan kebijakan dan prosedur yang berlaku di perusahaan Kami setelah memperoleh penyampaian keluhan yang lengkap dari Anda.
                                    </li>
                                <li>
                                    <b style={{ display: 'block' }}>Koreksi</b>
                                    <ul style={{ listStyleType: 'decimal' }}>
                                        <li>
                                            Dalam hal menurut catatan Kami terjadi kesalahan dalam transaksi,
                                            maka Kami dapat melakukan koreksi terhadap Deposit M Reload Anda.
                                            Kami tidak akan melakukan koreksi terhadap Deposit M Reload Anda
                                            tanpa bukti atau dasar yang kuat adanya kesalahan dalam transaksi.
                                            Anda dapat menyampaikan permintaan koreksi kepada Kami melalui jalur yang Kami sediakan.
                                            Kami akan mengambil langkah – langkah yang diperlukan dan memutuskan kasus per kasus.

                                            </li>
                                        <li>
                                            Sehubungan dengan kepentingan koreksi sebagaimana disebutkan di atas, Anda dengan ini memberikan kuasa yang tidak dapat ditarik kembali kepada Kami untuk mendebet dan/atau mengkredit Deposit M Reload Anda.
                                            Kuasa sebagaimana dimaksud akan terus berlaku dan tidak dapat berakhir karena alasan apapun juga termasuk karena alasan-alasan sebagaimana diatur dalam Pasal 1813, 1814, dan 1816 Kitab Undang-Undang Hukum Perdata, kecuali Anda menutup akun Anda.
                                            </li>
                                    </ul>
                                </li>
                                <li>
                                    <b style={{ display: 'block' }}>Force Majeure (Keadaan Memaksa)</b>
                                        Layanan Kami dapat diinterupsi oleh kejadian atau hal tertentu di luar kewenangan dan kontrol
                                         Kami <b>(Force Majeure)</b> , termasuk namun tidak terbatas pada bencana alam, gangguan listrik,
                                         gangguan telekomunikasi, kebijakan pemerintah, dan hal-hal lainnya yang di luar kewenangan dan kontrol Kami.
                                         Anda oleh karenanya setuju untuk melepaskan Kami dari setiap klaim, jika Kami tidak dapat memenuhi instruksi
                                         Anda melalui akun M Reload baik sebagian maupun seluruhnya karena <b>Force Majeure</b>.

                                    </li>
                                <li>
                                    <b style={{ display: 'block' }}>  Hak Kekayaan Intelektual</b>
                        Aplikasi ini termasuk namun tidak terbatas pada kode, desain, teknologi,
                        model bisnis, serta layanan yang disediakan oleh
                        Ayoconnect kepada Selular Group dilindungi oleh undang – undang hak cipta,
                        merek dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia.
                        Ayoconnect memiliki seluruh hak dan kepentingan atas aplikasi ini, termasuk seluruh hak kekayaan intelektual yang
                        berhubungan dengannya. Syarat dan Ketentuan ini tidak dan dengan cara apapun tidak akan dianggap sebagai
                        pemberian izin kepada Anda untuk menggunakan setiap hak kekayaan intelektual Kami sebagaimana disebutkan di atas.
                </li>
                                <li>
                                    <b style={{ display: 'block' }}>  Tidak ada Pengesampingan</b>
                    Pengesampingan Kami atau ditundanya atau kegagalan
                    Kami untuk melakukan klaim atas pelanggaran ketentuan manapun dalam Syarat dan Ketentuan
                    ini atau untuk melaksanakan setiap hak yang disediakan dalam Syarat dan Ketentuan ini tidak
                    akan dianggap sebagai pengesampingan sehubungan dengan pelanggaran substansial atas setiap ketentuan dalam
                    Syarat dan Ketentuan ini.

                </li>

                                {/* 13' */}
                <li>
                    <b style={{ display: 'block' }}>  Bahasa</b>
                    Syarat dan Ketentuan ini dibuat dalam Bahasa Indonesia.
                </li>

                                <li>
                                    <b style={{ display: 'block' }}> Pengalihan</b>
                Anda menjamin bahwa penggunaan akun M Reload hanya digunakan oleh Anda dan untuk kepentingan Anda sendiri sebagai pemegang akun sesuai dengan Syarat dan Ketentuan ini. Anda tidak boleh mengalihkan hak Anda berdasarkan Syarat dan Ketentuan ini tanpa persetujuan tertulis terlebih dahulu dari Kami. Jika ada pihak lain yang menggunakan akun Anda dan/atau jika ada pihak lain yang bertindak sebagai penerima manfaat (Beneficial Owner) Akun Anda selain Anda, maka Anda wajib melaporkan kepada Kami melalui info@Ayopop.com. Jika tidak ada pelaporan dari Anda mengenai pihak lain sebagai penerima manfaat atau Beneficial Owner maka Anda memberikan jaminan bahwa Anda adalah satu - satunya penerima manfaat atau Beneficial Owner dari Akun Anda.
                <br />Akun M Reload Anda tidak dapat dialihkan kepada pihak lain. Kami dapat mengalihkan hak Kami berdasarkan Syarat dan Ketentuan ini kepada pihak ketiga lain manapun berdasarkan kewenangan Kami sepenuhnya.


                </li>


                                <li>
                                    <b style={{ display: 'block' }}>Keterpisahan</b>
                    Jika setiap ketentuan dalam Syarat dan Ketentuan ini ditentukan sebagai ilegal, tidak sah atau tidak dapat dilaksanakan,
                    seluruh ataupun sebagian, berdasarkan peraturan atau prinsip hukum manapun, ketentuan atau sebagian daripadanya,
                    sejauh hal tersebut ilegal, tidak sah atau tidak dapat dilaksanakan, akan dianggap bukan merupakan bagian dari
                    Syarat dan Ketentuan ini tetapi legalitas,
                    validitas atau pelaksanaan dari ketentuan lainnya dari Syarat dan Ketentuan ini tidak akan berpengaruh.
               </li>

                                <li>
                                    <b style={{ display: 'block' }}>  Hukum yang Mengatur</b>
                    Syarat dan Ketentuan ini diatur berdasarkan hukum Republik Indonesia. Setiap dan seluruh perselisihan yang timbul dari penggunaan layanan
                    Kami akan tunduk pada yurisdiksi eksklusif Pengadilan Negeri Jakarta Selatan.
               </li>


                                <li>
                                    <b style={{ display: 'block' }}> Perubahan</b>
                    Syarat dan Ketentuan ini, sebagian atau seluruhnya, termasuk setiap fitur atau layanan yang ditawarkan dalam akun M Reload, dapat dimodifikasi, ditambahkan, atau diubah dari waktu ke waktu berdasarkan kebijakan Kami sendiri dengan atau tanpa pemberitahuan kepada Anda.
                    <br />Dalam hal terjadi perubahan fee yang tidak diterbitkan secara langsung oleh Selular Group, maka perubahan fee yang dimaksud merupakan tanggung jawab pihak ketiga yang melakukan kerja sama dan / atau berafiliasi dengan Selular Group.
                    <br />Penggunaan Anda secara berkelanjutan atas Aplikasi M Reload setelah modifikasi, variasi dan/atau perubahan atas Syarat dan Ketentuan merupakan persetujuan dan penerimaan Anda atas modifikasi, variasi dan/atau perubahan tersebut.
                    <b>Jika Anda ingin menolak modifikasi, variasi dan/atau perubahan tersebut, maka Anda harus berhenti mengakses atau menggunakan aplikasi M Reload dan/atau layanan kami serta mengakhiri Syarat & Ketentuan Penggunaan ini</b>

                                </li>


                                <li>
                                    <b style={{ display: 'block' }}>Kebijakan Privasi</b>
                Data personal Anda akan dijaga berdasarkan Kebijakan Privasi Kami.
                Kebijakan Privasi dianggap sebagai bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini dan persetujuan Anda atas
                Syarat dan Ketentuan ini merupakan penerimaan Anda atas Kebijakan Privasi.

               </li>



                            </ul>
                        </li>


                    </ul>
                </li>
                {/* main ul   */}


            </ul>
                <em>
                    Penggunaan Anda atas akun M Reload dan/atau layanan Selular Group
                    menandakan persetujuan Anda untuk tunduk pada (i) Syarat dan Ketentuan ini; (ii)
                    syarat dan ketentuan umum penggunaan Aplikasi M Reload; (iii) syarat dan ketentuan khusus yang berlaku untuk masing-masing fitur layanan yang tersedia dalam aplikasi M Reload; (iv) kebijakan privasi; dan (v)
                    setiap hukum dan peraturan perundang-undangan yang berlaku.
                </em>
            <br/>
        </Container>
    );
}








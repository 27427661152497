import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import ayoconnectLogo from './ayoconnect.png';
import SelularLogo from './reload.svg';
import Map from './indoMap.png';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const styles = theme => ({
  root: {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundImage: 'url(/static/static/images/gradientOverlay.png), url(/static/static/images/Logo.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
  },
  flex: {
      flex: 1
  },
  logoImage : {
      height : '50px',
      display:'block',
      margin:'0 auto 30px',
  },
  paper: {
      padding: theme.spacing(4,0,4),
  },
  form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      marginBottom: '0',
  },
  submit: {
      margin: theme.spacing(2, 0, 0),
      padding: '14px',
      backgroundColor: '#19A585',
      color:'white',
      fontWeight:'600',
      '&:hover': {
          backgroundColor: '#19A585',
      }
  },
  loginBlock : {
      borderRadius: '16px',
      backgroundColor: '#FFFFFF',
      zIndex :'100',
      padding: theme.spacing(4),
      backgroundImage: `url(${Map})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
  },
  forgotLink :{
      color: '#004C97',
      fontWeight:'400'
  }
});

const CssTextField = withStyles({
  root: {
      '& label.Mui-focused': {
          color: '#004C97',
      },
      '& .MuiOutlinedInput-root': {
          backgroundColor : 'white',
          '&.Mui-focused fieldset': {
              borderColor: '#004C97',
          },
      },
  },
})(TextField);



class App extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: '',
      password: '',
      showPassword: false,
      disableButton: false,
      emailErrFlag:false,
      pwdErrFlag: false,
      redirect:false,
      emailErr: '      ',
      passwordErr: '     '
    }
  }


  handlePasswordChange(value) {
    this.setState({ 
        disabled : false,
        'password': value, 
        'passwordErr': '' ,
        pwdErrFlag: false,
    });
};

handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword  });
};



handleUserEmailChange(value) {
    console.log(value);
    this.setState({ 
        disabled : false,
        'userEmail': value, 
        'emailErr' :'',
         emailErrFlag: false,
     });
}

handleLogin(e) {
  e.preventDefault();
  
  if(this.state.userEmail.length < 1 || this.state.userEmail !== "sales.mreload"){
    this.setState({
      emailErr: 'Username yang Anda masukkan salah.',
      emailErrFlag: true,
      passwordErr : '',
      pwdErrFlag : false,
      disabled : true

    })
    localStorage.removeItem("login");
  }
  else if(this.state.password.length < 1 || this.state.password !== "mreload.ayoconnect"){
    this.setState({
      passwordErr: 'Password yang Anda masukkan salah.',
      pwdErrFlag: true,
      emailErr: '',
      emailErrFlag: false,
      disabled : true
    })
    localStorage.removeItem("login");
  }else {
    this.setState({
        passwordErr: '',
        emailErr: '',
        emailErrFlag: false,
        pwdErrFlag: false,
    })
    localStorage.setItem("login",true);
    window.location.href = "/download"; 
  }
}


  render(){
    const { classes } = this.props;
  return (
      <div className={classes.root}>
              <div className={classes.paper}  >
                  <Container maxWidth="xs" style={{padding:'0px'}}>
                      <CssBaseline />
                      <div>
                          <div className={classes.loginBlock} style={{ boxShadow: '0 40px 32px - 24px rgba(0, 0, 0, 0.1)' }}>
                              <img src={SelularLogo}  alt="MReloadImage" className={classes.logoImage} />
                              <form className={classes.form} noValidate autoComplete="off" id="demo">
                                  <Grid container spacing={2}>
                                      <Grid item xs={12} sm={12}>
                                          <CssTextField
                                              error={this.state.emailErrFlag}
                                              variant="outlined"
                                              margin="normal"
                                              required
                                              fullWidth
                                              id="username"
                                              type="username"
                                              label="Username"
                                              name="username"
                                              autoComplete="username"
                                              onChange={(e) => this.handleUserEmailChange(e.target.value)}
                                              helperText={this.state.emailErr}
                                          /> 
                                      </Grid>
                                      <Grid item xs={12} sm={12}>
                                          <CssTextField
                                              id="outlined-adornment-password"
                                              className={clsx(classes.margin, classes.textField)}
                                              error={this.state.pwdErrFlag}
                                              margin="normal"
                                              fullWidth
                                              required
                                              variant="outlined"
                                              autoComplete="current-password"
                                              type={this.state.showPassword ? 'text' : 'password'}
                                              label="Password"
                                              value={this.state.password}
                                              onChange={(e) => this.handlePasswordChange(e.target.value)}
                                              helperText={this.state.passwordErr}
                                              InputProps={{
                                                  endAdornment: (
                                                      <InputAdornment position="end">
                                                          <IconButton
                                                              edge="end"
                                                              aria-label="Toggle password visibility"
                                                              onClick={() => this.handleClickShowPassword()}
                                                          >
                                                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                          </IconButton>
                                                      </InputAdornment>
                                                  ),
                                              }}  
                                          />
                                      </Grid>
                                      <Grid item xs={12}>
                                          <Button
                                              disabled={this.state.disableButton}
                                              type="button"
                                              fullWidth
                                              color="primary"
                                              variant="contained"
                                              className={classes.submit}
                                              onClick={(e) => this.handleLogin(e)}
                                              >
                                              MASUK
                                          </Button>

                                        
                                      </Grid>
                                  </Grid>
                              </form>

                          </div>
                      </div>
                  </Container>
                 
                  <div style={{position :'fixed',left : '0', right: '0' ,bottom:'20px',textAlign :'center'}}>
                    <p style={{ color : '#10407C', fontWeight : '500', fontSize : '10px'}}>
                        Powered By
                    </p>
                    <img src={ayoconnectLogo} alt="AyoconnectImage" style={{height :'20px'}}/>
                 </div>
              </div>
             
      </div>
             
          

    // </Container>

   )};
}


export default withStyles(styles)(App);


